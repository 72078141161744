import React from "react";
import Helmet from "react-helmet";

export default function About() {
  return (
    <div className="about">
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="Originally formed in 2019, BANDOFYOU represents the union of two
          LA-native songwriter-producer-musical directors, John Michael Anderson
          and Tyler Four Names. Apart from each other, they’ve each spent the
          better part of the past decade working behind the scenes with some of
          today’s biggest artists. Figuring it was time to step out of the
          darkness, they've come together as BANDOFYOU to release their first
          ever single, Oblivion, which is sure to instantly teleport you into
          the world that they inhabit, as you join them for a drive down the
          Pacific Coast Highway with the windows down on a sunny California
          afternoon."
        />
      </Helmet>
      <div className="content-wrap">
        <p>
          Originally formed in 2019, BANDOFYOU represents the union of two
          LA-native songwriter-producer-musical directors, John Michael Anderson
          and Tyler Four Names. Apart from each other, they’ve each spent the
          better part of the past decade working behind the scenes with some of
          today’s biggest artists. Figuring it was time to step out of the
          darkness, they've come together as BANDOFYOU to release their first
          ever single, Oblivion, which is sure to instantly teleport you into
          the world that they inhabit, as you join them for a drive down the
          Pacific Coast Highway with the windows down on a sunny California
          afternoon.
        </p>
      </div>
    </div>
  );
}
