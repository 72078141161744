import React, { Fragment } from "react";
import Helmet from "react-helmet";
import cover from "./imgs/cover.jpg";

export default function News() {
  return (
    <Fragment>
      <Helmet>
        <title>Presskit</title>
        <meta
          name="description"
          content="Originally formed in 2019, BANDOFYOU represents the union of two
          LA-native songwriter-producer-musical directors, John Michael Anderson
          and Tyler Four Names. Apart from each other, they’ve each spent the
          better part of the past decade working behind the scenes with some of
          today’s biggest artists. Figuring it was time to step out of the
          darkness, they've come together as BANDOFYOU to release their first
          ever single, Oblivion, which is sure to instantly teleport you into
          the world that they inhabit, as you join them for a drive down the
          Pacific Coast Highway with the windows down on a sunny California
          afternoon."
        />
      </Helmet>
      <div className="press">
        <div className="left">{/* band image background */}</div>
        <div className="right">
          <p>
            Originally formed in 2019, BANDOFYOU represents the union of two
            LA-native songwriter-producer-musical directors, John Michael
            Anderson and Tyler Four Names. Apart from each other, they’ve each
            spent the better part of the past decade working behind the scenes
            with some of today’s biggest artists. Figuring it was time to step
            out of the darkness, they've come together as BANDOFYOU to release
            their first ever single, Oblivion, which is sure to instantly
            teleport you into the world that they inhabit, as you join them for
            a drive down the Pacific Coast Highway with the windows down on a
            sunny California afternoon.
          </p>
          <div className="album">
            <div className="item">
              <img src={cover} alt="album cover" />
            </div>
            <div className="item-2">
              <h2>oblivion out now</h2>
              <a
                href="https://music.apple.com/us/album/oblivion-single/1551450812?uo=4"
                target="_blank"
                rel="noreferrer"
                className="button"
                style={{ marginTop: 10 }}
              >
                Listen Apple Music
              </a>
              <a
                href="https://open.spotify.com/album/5f4EPZdfWUcHNRDjT7KbHi"
                target="_blank"
                rel="noreferrer"
                className="button"
                style={{ marginTop: 10 }}
              >
                Listen Spotify
              </a>
              <a
                href="https://distrokid.com/hyperfollow/bandofyou/oblivion"
                target="_blank"
                rel="noreferrer"
                className="button"
                style={{ marginTop: 10 }}
              >
                More Listening Options
              </a>
            </div>
          </div>
          <div className="flex contact">
            <p>
              <a href="mailto:sean@angermgmtgroup.com">
                MGMT: sean@angermgmtgroup.com
              </a>
            </p>
            <p>
              <a href="mailto:bandofyou@gmail.com">bandofyou@gmail.com</a>
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
