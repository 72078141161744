import logo from "./logo.svg";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  IoLogoTiktok,
  IoLogoInstagram,
  IoLogoTwitter,
  IoLogoYoutube,
} from "react-icons/io5";
import Home from "./Home";
import About from "./About";
import Epk from "./Epk";
import News from "./News";

function App() {
  return (
    <Router>
      <div className="App">
        <header className="header">
          <h1>
            <Link to="/">
              <img src={logo} className="logo" alt="logo" />
            </Link>
          </h1>
          <nav>
            <ul>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <a
                  href="https://linktr.ee/bandofYOU"
                  target="_blank"
                  rel="noreferrer"
                >
                  Linktree
                </a>
              </li>
            </ul>
          </nav>
        </header>

        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/presskit">
            <Epk />
          </Route>
          <Route path="/epk">
            <Epk />
          </Route>
          <Route path="/news">
            <News />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>

        <div className="social">
          <a
            href="https://www.instagram.com/bandofyou"
            target="_blank"
            rel="noreferrer"
          >
            <IoLogoInstagram />
          </a>
          <a
            href="https://www.tiktok.com/@bandofyou"
            target="_blank"
            rel="noreferrer"
          >
            <IoLogoTiktok />
          </a>
          <a
            href="https://twitter.com/bandofyou"
            target="_blank"
            rel="noreferrer"
          >
            <IoLogoTwitter />
          </a>
          <a
            href="https://www.youtube.com/channel/UC3bQbk8dzbe1vEyHqF9pijQ"
            target="_blank"
            rel="noreferrer"
          >
            <IoLogoYoutube />
          </a>
        </div>

        <footer>bandofyou © {new Date().getFullYear()}</footer>
      </div>
    </Router>
  );
}

export default App;
