import React from "react";
import Helmet from "react-helmet";
import img1 from "./epk/bandofyou-1.jpg";
import img2 from "./epk/bandofyou-2.jpg";
import img3 from "./epk/bandofyou-3.jpg";
import img4 from "./epk/bandofyou-4.jpg";
import logo from "./epk/bandofyou-logo.png";

export default function Epk() {
  return (
    <div
      className="epk
    "
    >
      <Helmet>
        <title>Press Kit</title>
        <meta name="description" content="bandofyou presskit" />
      </Helmet>
      <div className="content-wrap">
        <div className="item">
          <img src={logo} alt="logo" />
          <div className="links">
            <a
              href="http://angermusicgroup.com/bandofyou/img/epk/logo/boy-black-transparent.png"
              target="_blank"
              rel="noopener noreferrer"
            >
              WEB: 72DPI
            </a>
          </div>
        </div>
        <div className="item">
          <img src={img4} alt="logo" />
          <div className="links">
            <a
              href="http://angermusicgroup.com/bandofyou/img/epk/small/You_Band_2019_JW_241-NEW.jpg"
              target="_blank"
              rel="noopener noreferrer"
            >
              WEB: 72DPI
            </a>
            <a
              href="http://angermusicgroup.com/bandofyou/img/epk/large/You_Band_2019_JW_241-NEW.jpg"
              target="_blank"
              rel="noopener noreferrer"
            >
              PRINT: 300DPI
            </a>
          </div>
        </div>
        <div className="flex">
          <div className="item">
            <img src={img2} alt="logo" />
            <div className="links">
              <a
                href="http://angermusicgroup.com/bandofyou/img/epk/small/You_Band_2019_JW_187-NEW.jpg"
                target="_blank"
                rel="noopener noreferrer"
              >
                WEB: 72DPI
              </a>
              <a
                href="http://angermusicgroup.com/bandofyou/img/epk/large/You_Band_2019_JW_187-NEW.jpg"
                target="_blank"
                rel="noopener noreferrer"
              >
                PRINT: 300DPI
              </a>
            </div>
          </div>
          <div className="item">
            <img src={img3} alt="logo" />
            <div className="links">
              <a
                href="http://angermusicgroup.com/bandofyou/img/epk/small/You_Band_2019_JW_196-NEW.jpg"
                target="_blank"
                rel="noopener noreferrer"
              >
                WEB: 72DPI
              </a>
              <a
                href="http://angermusicgroup.com/bandofyou/img/epk/large/You_Band_2019_JW_196-NEW.jpg"
                target="_blank"
                rel="noopener noreferrer"
              >
                PRINT: 300DPI
              </a>
            </div>
          </div>
        </div>{" "}
        <div className="item">
          <img src={img1} alt="logo" />
          <div className="links">
            <a
              href="http://angermusicgroup.com/bandofyou/img/epk/small/You_Band_2019_JW_177-NEW.jpg"
              target="_blank"
              rel="noopener noreferrer"
            >
              WEB: 72DPI
            </a>
            <a
              href="http://angermusicgroup.com/bandofyou/img/epk/large/You_Band_2019_JW_177-NEW.jpg"
              target="_blank"
              rel="noopener noreferrer"
            >
              PRINT: 300DPI
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
