import React, { Fragment } from "react";
import Helmet from "react-helmet";
import tallImg from "./imgs/tall1.jpg";

export default function Home() {
  return (
    <Fragment>
      <Helmet>
        <title>Home</title>
        <meta
          name="description"
          content="Originally formed in 2019, BANDOFYOU represents the union of two
          LA-native songwriter-producer-musical directors, John Michael Anderson
          and Tyler Four Names. Apart from each other, they’ve each spent the
          better part of the past decade working behind the scenes with some of
          today’s biggest artists. Figuring it was time to step out of the
          darkness, they've come together as BANDOFYOU to release their first
          ever single, Oblivion, which is sure to instantly teleport you into
          the world that they inhabit, as you join them for a drive down the
          Pacific Coast Highway with the windows down on a sunny California
          afternoon."
        />
      </Helmet>

      <div className="home">
        <div className="left">
          <img src={tallImg} alt="john and tyler" />
        </div>
        <div className="right">
          <h2>oblivion out now</h2>
          <h2>oblivion out now</h2>
          <h2>oblivion out now</h2>
          <a
            href="https://distrokid.com/hyperfollow/bandofyou/oblivion"
            target="_blank"
            rel="noreferrer"
            className="button"
            style={{ marginTop: 10 }}
          >
            Listen Now
          </a>
        </div>
      </div>
    </Fragment>
  );
}
